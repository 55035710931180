/*
Copyright 2022 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

let audioContext: AudioContext | null = null;
let refCount = 0;

/**
 * Acquires a reference to the shared AudioContext.
 * It's highly recommended to reuse this AudioContext rather than creating your
 * own, because multiple AudioContexts can be problematic in some browsers.
 * Make sure to call releaseContext when you're done using it.
 * @returns {AudioContext} The shared AudioContext
 */
export const acquireContext = (): AudioContext => {
    if (audioContext === null) audioContext = new AudioContext();
    refCount++;
    return audioContext;
};

/**
 * Signals that one of the references to the shared AudioContext has been
 * released, allowing the context and associated hardware resources to be
 * cleaned up if nothing else is using it.
 */
export const releaseContext = () => {
    refCount--;
    if (refCount === 0) {
        audioContext.close();
        audioContext = null;
    }
};
